import { MembersLabelWithCountDict } from './MembersLabel';
import React from 'react';
import { withTranslation, WithTranslation } from '@wix/yoshi-flow-editor';

interface MembersLabelWithCountProps {
  groupMemberLabel?: string;
  count: number;
}

const MembersLabelWithCountComponent = (
  props: MembersLabelWithCountProps & WithTranslation,
) => {
  const { t, groupMemberLabel, count } = props;
  const translationKey = groupMemberLabel
    ? // @ts-expect-error
      MembersLabelWithCountDict[groupMemberLabel]
    : MembersLabelWithCountDict.Members;

  const label = translationKey
    ? t(translationKey, {
        count,
      })
    : `${count} ${groupMemberLabel}`;
  return <span data-hook="members-count">{label}</span>;
};

export const MembersLabelWithCount = withTranslation()(
  MembersLabelWithCountComponent,
) as React.ComponentType<MembersLabelWithCountProps>;
