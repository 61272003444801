import React from 'react';
import { ApiTypes } from '@wix/social-groups-api/dist/src/types';
import { WithTranslation } from '@wix/yoshi-flow-editor';

// @ts-expect-error
export const GroupPrivacyLevel: React.FC<
  Partial<WithTranslation> & {
    privacyLevel: ApiTypes.v1.GroupPrivacyLevel;
  }
> = ({ t, privacyLevel }) => {
  return t?.(`groups-web.group-list.privacy.${privacyLevel.toLowerCase()}`);
};
