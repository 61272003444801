import React from 'react';
import { UpdateProgress } from '../../../common/ContentEditor/UpdateProgress';
import { GroupsPageData } from '../Widget/App/types';

export const GroupsContext = React.createContext<Partial<GroupsPageData>>({
  updateProgress: UpdateProgress.UPDATING,
});
GroupsContext.displayName = 'GroupsContext';

export const GroupsProvider = GroupsContext.Provider;
export const GroupsConsumer = GroupsContext.Consumer;
export default GroupsContext;
