import React, { FC } from 'react';
import { WidgetProps } from '@wix/yoshi-flow-editor';
import { withWidgetWCS } from '@wix/yoshi-flow-editor/wcs/wrappers';
import { widgetSettingsParams } from '../../../common/settings/groups-list/widgetSettings';
import { stylesParams } from '../../../common/settings/groups-list/styles';
import { WidgetRoot } from './WidgetRoot/WidgetRoot';
import { GroupPageWithStyles } from '../../Groups/Widget/App/types';

const Widget: FC<WidgetProps<GroupPageWithStyles>> = (props) => {
  return <WidgetRoot {...props} />;
};

export default Widget;
export const GroupsListWidgetWCS = withWidgetWCS(
  Widget,
  widgetSettingsParams,
  stylesParams,
);
