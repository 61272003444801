import React from 'react';
import classnames from 'classnames';
import { GroupCardMedia } from '../media/GroupCardMedia';
import { GroupListCardProps } from '../types';
import { GroupCardMeta } from '../meta/GroupCardMeta';
import { GroupCardCTA } from '../cta/GroupCardCTA';

import { classes, st } from './GroupListCard.st.css';
import styles from './GroupListCard.scss';

type Props = GroupListCardProps;
export const GroupListCard: React.FC<Props> = ({
  group,
  goToGroup,
  imageRatio,
  hasSpacing,
  mobile,
  cardMetaComponent: MetaComponent, // TODO: Types broken! refactor? polymorphism? should have one interface
  cardActionsComponent: CardActionsComponent,
  showGroupLogo,
  layout,
  groupUrl,
  ...props
}) => {
  const { details } = group;
  const coverImage = details?.logo?.mediaId || details?.logoUrl;
  const className = classnames(
    classes[layout!],
    props.className,
    {
      [styles.mobile]: mobile,
    },
    styles.list,
  );
  return (
    <div
      className={st(
        classes.root,
        {
          imageRatio,
          hasSpacing,
          mobile,
          withItemClick: !!goToGroup,
        },
        className,
      )}
      onClick={goToGroup ? goToGroup : undefined}
      data-bi-origin="group_image"
      data-hook="group-list-item"
    >
      <div className={classes.mediaWithMeta}>
        {showGroupLogo && (
          <div className={classes.mediaContainer}>
            <GroupCardMedia
              url={coverImage!}
              ratio={imageRatio}
              groupUrl={groupUrl}
            />
          </div>
        )}
        {MetaComponent ? (
          <MetaComponent
            group={group}
            groupUrl={groupUrl}
            className={classes.listCardMeta}
          />
        ) : null}
      </div>
      {CardActionsComponent ? (
        <CardActionsComponent
          group={group}
          groupUrl={groupUrl}
          className={styles.action}
        />
      ) : null}
    </div>
  );
};

GroupListCard.defaultProps = {
  cardMetaComponent: GroupCardMeta,
  cardActionsComponent: GroupCardCTA,
};
