import React from 'react';

import {
  TPAComponentsConsumer,
  TPAComponentsConfig,
} from 'wix-ui-tpa/TPAComponentsConfig';

export const withTPAConfig =
  <P extends TPAComponentsConfig>(WrappedComponent: React.ComponentType<P>) =>
  (props: any) => {
    return (
      <TPAComponentsConsumer>
        {(config) => <WrappedComponent {...props} {...config} />}
      </TPAComponentsConsumer>
    );
  };
