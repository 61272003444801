import React from 'react';
import { GroupWrapper } from '@wix/social-groups-api/dist/src/model/Group/GroupWrapper';
import { ApiTypes } from '@wix/social-groups-api/dist/src/types';

import { GroupPrivacyLevel } from './GroupPrivacy';
import { classes, st } from './GroupCardMeta.st.css';
import classnames from 'classnames';
import { TPAComponentsConfig } from 'wix-ui-tpa/dist/es/src';
import { GROUP_TITLE_LINK } from './dataHooks';
import { getSettingsKeyFor, isUndefinedOrTrue } from '../../../utils/utils';
import { MembersLabelWithCount } from '../../MembersLabel/MembersLabelWithCount';
import { RoleBadges } from '../../RoleBadges';
import {
  st as badgesSt,
  classes as badgesClasses,
} from '../../RoleBadges/GroupsListBadge.st.css';
import { withTPAConfig } from '../../withTPAConfig';
import { useSettings } from '@wix/yoshi-flow-editor/tpa-settings/react';
import { settingsParams } from '../../../settings/groups-list/settings';
import { widgetSettingsParams } from '../../../settings/groups-list/widgetSettings';
import { useTranslation } from '@wix/yoshi-flow-editor';

interface GroupCardMetaProps {
  group: ApiTypes.v1.GroupResponse;
  groupUrl: string;
  className?: string;
}

type Props = GroupCardMetaProps & TPAComponentsConfig;

export const GroupCardMetaComponent: React.FC<Props> = ({
  group,
  groupUrl,
  mobile,
  className,
}) => {
  const { relationship, roles } = group;
  const groupWrapper = new GroupWrapper(group);
  const memberCount = groupWrapper.getMembersCount();
  const pendingMembersCount = groupWrapper.getPendingMembersCount();
  const membersName = groupWrapper.getMembersName();
  const privacyLevel = groupWrapper.getPrivacy();

  const settings = useSettings();

  const key = getSettingsKeyFor('showMemberCount', !!mobile);
  const showMembersCount = settings.get(settingsParams[key]);
  // TODO: widgetSettingsParams?
  const groupType = isUndefinedOrTrue(
    settings.get(widgetSettingsParams.showGroupType),
  );

  const { t } = useTranslation();
  return (
    <div
      className={st(
        classes.root,
        { mobile } as any,
        classnames(classes.meta, className),
      )}
    >
      <div className={classes.header}>{renderTitleLink()}</div>
      <p className={classes.details}>
        {groupType && <GroupPrivacyLevel t={t} privacyLevel={privacyLevel} />}
        {showMembersCount && groupType && <span>&nbsp;&middot;&nbsp;</span>}
        {showMembersCount && renderMembersCount()}
        {showMembersCount && renderPendingMembersCount()}
      </p>
    </div>
  );

  function renderMembersCount() {
    return (
      <MembersLabelWithCount
        groupMemberLabel={membersName}
        count={memberCount}
      />
    );
  }

  function renderPendingMembersCount() {
    return pendingMembersCount ? (
      <span>
        &nbsp;&middot;&nbsp;
        <span className={classes.pendingMembers}>
          {t('groups-web.group-list.pending-members.count', {
            count: pendingMembersCount,
          })}
        </span>
      </span>
    ) : null;
  }
  function renderTitleLink() {
    const k = getSettingsKeyFor('showAdminBadge', !!mobile);
    const showAdminBadge = settings.get(settingsParams[k]);
    return (
      <>
        <a
          data-hook={GROUP_TITLE_LINK}
          className={classes.link}
          href={groupUrl}
          data-bi-origin="group_title"
        >
          {group.details?.title}
        </a>
        {showAdminBadge && (
          <RoleBadges
            relationship={relationship!}
            roles={roles!}
            getBadgeClassName={(isAdmin) =>
              badgesSt(badgesClasses.badge, { isAdmin })
            }
          />
        )}
      </>
    );
  }
};

export const GroupCardMeta = withTPAConfig(GroupCardMetaComponent);
