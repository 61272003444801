import React from 'react';

import { TextField } from 'wix-ui-tpa/TextField';
import { Text } from 'wix-ui-tpa/Text';

import { classes, st } from './MembershipQuestion.st.css';
import { ApiTypes } from '@wix/social-groups-api/dist/src/types';
import { ValidationError } from '@wix/social-groups-api/dist/src/model/MembershipAnswers/MembershipQuestionAnswers';
import { WithTranslation, withTranslation } from '@wix/yoshi-flow-editor';
import { compose } from '../../../utils/compose';
import { withTheme, Theme } from '../../../context/theme';

interface QuestionAnswerProps {
  questionAnswer: ApiTypes.v1.QuestionAnswer;
  index: number;
  forceFocus: boolean;
  error?: ValidationError;
  className?: string;
  onChange(questionId: string, answer: string): void;
  onBlur(questionId: string): void;
}

interface State {
  answer: string;
}

type Props = QuestionAnswerProps & WithTranslation & Theme;

class QuestionAnswerComponent extends React.Component<Props, State> {
  private answerRef: any;

  render() {
    const { questionAnswer, t, forceFocus, index, error, forceBlackAndWhite } =
      this.props;

    if (this.answerRef && forceFocus) {
      this.answerRef.focus();
    }

    const { question } = questionAnswer;
    return (
      <div
        className={st(
          classes.root,
          { bw: !!forceBlackAndWhite },
          this.props.className,
        )}
      >
        <div className={classes.questionWrap}>
          <Text className={classes.question}>
            {index}. {question?.textQuestion}
            {question?.required ? '*' : ''}
          </Text>
        </div>
        <TextField
          className={classes.questionAnswerInput}
          ref={this.setRef}
          autoFocus={forceFocus}
          value={questionAnswer.textAnswer!}
          error={error !== undefined}
          errorMessage={this.getErrorMessage()}
          onChange={this.handleAnswerOnChange}
          onBlur={this.handleAnswerOnBlur}
          placeholder={t(
            'groups-web.membership-questions.response.placeholder',
          )}
        />
      </div>
    );
  }

  setRef = (el: any) => (this.answerRef = el);

  handleAnswerOnChange = (evt: any) => {
    const { onChange, questionAnswer } = this.props;
    const answer = evt.target.value;
    this.setState({ answer });
    onChange(questionAnswer.question?.questionId!, answer);
  };

  handleAnswerOnBlur = () => {
    const { questionAnswer } = this.props;
    this.props.onBlur(questionAnswer.question?.questionId!);
  };

  getErrorMessage = (): string => {
    const { t, error } = this.props;

    if (error === undefined) {
      return '';
    }

    if (error === ValidationError.REQUIRED) {
      return t('groups-web.membership-questions.response.error');
    }

    if (error === ValidationError.MAX_LENGTH) {
      return t('groups-web.membership-questions.response.error-max_length');
    }
    return '';
  };
}

const enhance = compose(withTranslation(), withTheme);

export const QuestionAnswer = enhance(
  QuestionAnswerComponent,
) as React.ComponentType<QuestionAnswerProps>;
QuestionAnswer.displayName = 'QuestionAnswer';
