import React from 'react';
import { Theme } from './Theme';

const context = React.createContext<Theme>({
  forceBlackAndWhite: false,
});

context.displayName = 'ThemeContext';

export const ThemeProvider = context.Provider;
export const ThemeConsumer = context.Consumer;
