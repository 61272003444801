import React from 'react';
import { AppToastsProps } from '@wix/social-groups-api';
import { AppToastsController } from '../../controllers';

const context = React.createContext<AppToastsProps>(
  AppToastsController.emptyState,
);
context.displayName = 'AppToastsContext';

export const AppToastsProvider = context.Provider;
export const AppToastsConsumer = context.Consumer;
