import React from 'react';

import { GroupCardProps, GroupListCardProps } from './types';
import { classes, st } from './layout/GroupCard.st.css';
import { GroupGridCard } from './layout/GroupGridCard';
import { GroupListCard } from './layout/GroupListCard';
import { getSettingsKeyFor, isUndefinedOrTrue } from '../../utils/utils';
import { LayoutType } from '../../settings/groups-list/settingsConstants';
import { withTPAConfig } from '../withTPAConfig';
import { useSettings } from '@wix/yoshi-flow-editor/tpa-settings/react';
import { settingsParams } from '../../settings/groups-list/settings';

type Props = Omit<GroupCardProps, 'imageRatio'>;

export const GroupCardComponent: React.FC<Props> = ({ ...props }) => {
  const { mobile } = props;
  let Component: React.ComponentType<GroupCardProps | GroupListCardProps>;
  const settings = useSettings();
  const ik = getSettingsKeyFor('imageRatio', mobile);
  const imageRatio = settings.get(settingsParams[ik]);
  const lk = getSettingsKeyFor('groupListLayout', mobile);
  const layout = settings.get(settingsParams[lk]);
  const ck = getSettingsKeyFor('listCardSpacing', mobile);
  const hasSpacing = settings.get(settingsParams[ck]);

  const specificProps = {
    [LayoutType.list]: {
      hasSpacing,
    },
    [LayoutType.grid]: {},
  };

  switch (layout) {
    case LayoutType.grid:
      Component = GroupGridCard as any;
      Component.displayName = 'GroupGridCard';
      break;
    case LayoutType.list:
    default:
      Component = GroupListCard as any;
      Component.displayName = 'GroupListCard';
  }

  const sk = getSettingsKeyFor('showImage', mobile);
  const showImage = settings.get(settingsParams[sk]);
  return (
    <Component
      {...props}
      imageRatio={imageRatio}
      showGroupLogo={isUndefinedOrTrue(showImage)}
      layout={layout}
      {...(specificProps as any)[layout]}
      className={st(classes.root, { mobile })}
    />
  );
};

export const GroupCard = withTPAConfig(
  GroupCardComponent,
) as React.ComponentType<Omit<GroupCardProps, 'imageRatio'>>;
GroupCard.displayName = 'GroupCard';
