import React from 'react';
import { TPAComponentsConsumer } from 'wix-ui-tpa/TPAComponentsConfig';
import { Text } from 'wix-ui-tpa/Text';
import classNames from 'classnames';
import { TPAModal, TPAModalProps } from './TPAModal';

import { classes, st } from './ModalV2.st.css';
import { Theme, withTheme } from '../../context/theme';

export interface ModalV2FooterProps {
  okButton: React.ReactNode;
  cancelButton?: React.ReactNode;
  sideAction?: React.ReactNode;
  className?: string;
}

type IModal<P> = React.FunctionComponent<P> & {
  Content: React.FC<{
    className?: string;
  }>;
  Title: React.FC;
  Subtitle: React.FC;
  Footer: React.FC<ModalV2FooterProps>;
};

type ModalV2Props = TPAModalProps;

const ModalV2Component: React.FC<ModalV2Props & Theme> = (props) => (
  <TPAComponentsConsumer>
    {({ mobile }) => {
      const { inFullScreen, forceBlackAndWhite, children, ...rest } = props;
      return (
        <TPAModal
          inFullScreen={inFullScreen || mobile}
          {...rest}
          className={st(
            classes.root,
            { mobile, bw: !!forceBlackAndWhite } as any,
            rest.className,
          )}
        >
          {children}
        </TPAModal>
      );
    }}
  </TPAComponentsConsumer>
);
export const ModalV2 = withTheme(ModalV2Component) as IModal<ModalV2Props>;
ModalV2.displayName = 'ModalV2';
ModalV2.Title = ({ children }) => (
  <Text className={classes.title}>{children}</Text>
);
ModalV2.Subtitle = ({ children }) => (
  <Text className={classes.subtitle}>{children}</Text>
);
ModalV2.Content = ({ children, className }) => (
  <div className={classNames(classes.content, className)}>{children}</div>
);
ModalV2.Footer = ({ okButton, cancelButton, sideAction, className }) => (
  <div className={classNames(classes.footer, className)}>
    <div>{sideAction ? sideAction : null}</div>
    <div className={classes.buttons}>
      <div className={classes.cancelButtonWrapper}>{cancelButton}</div>
      {okButton}
    </div>
  </div>
);
ModalV2.displayName = 'ModalV2';
ModalV2.Title.displayName = 'ModalV2.Title';
ModalV2.Subtitle.displayName = 'ModalV2.Subtitle';
ModalV2.Content.displayName = 'ModalV2.Content';
ModalV2.Footer.displayName = 'ModalV2.Footer';
