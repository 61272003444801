import React from 'react';
import withGroups from '../../contexts/withGroups';
import withGroupsActions, {
  WithGroupsActionsProps,
} from '../../contexts/withGroupsActions';

import {
  canCreateGroup,
  isProfilePublic,
  willBePendingGroup,
} from '@wix/social-groups-api';
import { DATA_HOOKS } from './dataHooks';
import {
  withCurrentUser,
  WithCurrentUserProps,
} from '../../contexts/withCurrentUser';

import { WithGroupsProps } from '../../contexts/withGroupsProps';

import { GroupWrapper } from '@wix/social-groups-api/dist/src/model/Group/GroupWrapper';
import { WithAppToastsProps } from '../../../../common/components/AppToats/withAppToastsProps';
import {
  WixSiteMember,
  WixSiteMemberActions,
} from '../../../../common/types/WixSiteMembers';
import { UpdateProgress } from '../../../../common/ContentEditor/UpdateProgress';
import { compose } from '../../../../common/utils/compose';
import { withAppToasts } from '../../../../common/components/AppToats/withAppToasts';
import { withWixSiteMembers } from '../../../../common/context/members';
import { withTPAConfig } from '../../../../common/components/withTPAConfig';
import {
  InjectedBiLoggerProps,
  withBi,
  withTranslation,
  WithTranslation,
  withExperiments,
  InjectedExperimentsProps,
} from '@wix/yoshi-flow-editor';
import { Spinner } from '../../../../common/components/Spinner/Spinner';
import { default as CreateGroupModal } from '../modals/CreateGroupModal/CreateGroupModal';
import { ProfilePrivacyDialog } from '../../../../common/components/PrivacyDialog/ProfilePrivacyDialog';
import { MembershipQuestionsModal } from '../../../../common/components/MembershipQuestionsModal/MembershipQuestionsModal';
import { GroupList } from '../../../../common/components/GroupList/GroupList';
import {
  WithSettingsProps,
  withSettings,
  WithStylesProps,
  withStyles,
} from '@wix/yoshi-flow-editor/tpa-settings/react';
import {
  groupsCreateGroupClick,
  groupsPageView,
  groupsSelectGroupFromList,
} from '@wix/bi-logger-groups/dist/src/v2';

export interface GroupsProps {}

export interface GroupsState {
  isCreateGroupModalOpened: boolean;
  isGroupCreating: boolean;
  isProfileDialogOpen: boolean;
}

export type ComponentProps = GroupsProps &
  WithCurrentUserProps &
  WithGroupsProps &
  WithGroupsActionsProps &
  WithAppToastsProps &
  WixSiteMember &
  WixSiteMemberActions &
  WithStylesProps &
  WithSettingsProps & { mobile: boolean } & InjectedBiLoggerProps &
  InjectedExperimentsProps &
  WithTranslation;

export class GroupsSectionComponent extends React.Component<
  ComponentProps,
  GroupsState
> {
  readonly state: GroupsState = {
    isCreateGroupModalOpened: false,
    isGroupCreating: false,
    isProfileDialogOpen: false,
  };

  componentDidUpdate(
    prevProps: Readonly<ComponentProps>,
    prevState: Readonly<GroupsState>,
  ): void {
    if (!prevProps.promptPublicProfile && this.props.promptPublicProfile) {
      this.openProfileModal();
    }
  }

  componentDidMount() {
    this.props.bi.report(
      groupsPageView({
        origin: 'list view',
      }),
    );
  }

  closeCreateGroupModal = () => {
    this.setState({ isCreateGroupModalOpened: false });
  };

  emptyStateCreateGroupButton = () => {
    this.openCreateGroupModal();
    this.props.bi.report(
      groupsCreateGroupClick({
        origin: 'empty_state_btn',
      }),
    );
  };

  openCreateGroupModal = () => {
    if (this.isProfilePrivate()) {
      return this.openProfileModal();
    }
    if (this.props.currentUser.loggedIn) {
      this.setState({ isCreateGroupModalOpened: true });
    } else {
      this.props.actions.promptLogin();
    }
  };

  handleCreateGroupClick = () => {
    this.props.bi.report(
      groupsCreateGroupClick({
        origin: 'plus_btn',
      }),
    );
    this.openCreateGroupModal();
  };

  handleViewGroupClick = (groupId: string) => {
    const { actions, bi } = this.props;
    bi.report(
      groupsSelectGroupFromList({
        componentName: 'groups',
        pageName: 'member_questions_screen',
        origin: 'member_questions_screen_link',
        groupId,
      }),
    );
    actions.goToGroup(groupId);
  };

  render() {
    const {
      actions,
      createGroupPolicy,
      currentUser,
      mobile,
      updateProgress,
      t,
    } = this.props;

    if (updateProgress === UpdateProgress.UPDATING) {
      return <Spinner offset="L" label={t('groups-web.group-list.loading')} />;
    }
    const { isCreateGroupModalOpened } = this.state;

    const shouldShowCreateGroupButton = canCreateGroup(
      currentUser,
      createGroupPolicy!,
    );

    const withPendingGroupExplanation = willBePendingGroup(
      currentUser,
      createGroupPolicy!,
    );

    return (
      <section key="groups">
        <GroupList
          data-hook={DATA_HOOKS.groupList}
          onCreateGroupClick={this.emptyStateCreateGroupButton}
          goToGroup={actions ? actions.goToGroup : undefined}
          withCTA={shouldShowCreateGroupButton}
          mobile={mobile}
        />
        <CreateGroupModal
          withPendingGroupExplanation={withPendingGroupExplanation}
          isOpen={isCreateGroupModalOpened}
          createGroup={actions && actions.createGroup}
          onRequestClose={this.closeCreateGroupModal}
        />
        {this.isProfilePrivate() && (
          <ProfilePrivacyDialog
            onRequestClose={this.closeProfileModal}
            onChangeProfile={this.changeProfile}
            isOpen={this.state.isProfileDialogOpen}
          />
        )}
        {this.renderMembershipQuestionsStep()}
      </section>
    );
  }

  private renderMembershipQuestionsStep() {
    const { groupQuestions, actions } = this.props;
    if (!groupQuestions) {
      return null;
    }
    const group = new GroupWrapper(groupQuestions.group);
    return (
      <MembershipQuestionsModal
        isOpen={!!groupQuestions}
        questions={groupQuestions.questions}
        groupId={group.getId()}
        groupName={group.getTitle()}
        onSubmit={this.submitAnswers}
        onViewGroupClick={() => this.handleViewGroupClick(group.getId())}
        onRequestClose={actions.rejectAnswers}
      />
    );
  }

  private openProfileModal() {
    this.setState({ isProfileDialogOpen: true });
  }

  private readonly changeProfile = () => {
    const { makeProfilePublic, currentSiteMember } = this.props;
    currentSiteMember && makeProfilePublic(currentSiteMember.id);
  };
  private readonly closeProfileModal = () =>
    this.setState({ isProfileDialogOpen: false });

  private isProfilePrivate() {
    return (
      this.props.currentSiteMember &&
      !isProfilePublic(this.props.currentSiteMember)
    );
  }

  private readonly submitAnswers = (answers: any) => {
    const { actions, groupQuestions } = this.props;
    actions.submitAnswers(groupQuestions.group, answers);
  };
}

const enhance = compose(
  withGroups,
  withCurrentUser,
  withGroupsActions,
  withAppToasts,
  withWixSiteMembers,
  withSettings,
  withStyles,
  withTPAConfig,
  withBi,
  withExperiments,
  withTranslation(),
);
export const GroupsSection = enhance(
  GroupsSectionComponent,
) as React.ComponentType<GroupsProps>;
