import React from 'react';
import { NewCard } from 'wix-ui-tpa/NewCard';
import { GroupCardMedia } from '../media/GroupCardMedia';
import { GroupCardProps } from '../types';
import { GroupCardMeta } from '../meta/GroupCardMeta';
import { GroupCardCTA } from '../cta/GroupCardCTA';

import classnames from 'classnames';
import { classes, st } from './GroupGridCard.st.css';
import styles from './GroupGridCard.scss';
import { ImageRatio } from '../../../settings/groups-list/settingsConstants';

export const GroupGridCard: React.FC<GroupCardProps> = ({
  group,
  goToGroup,
  imageRatio,
  mobile,
  showGroupLogo,
  groupUrl,
  className,
}) => {
  const { details } = group;
  const coverImage = details?.logo?.mediaId || details?.logoUrl;
  return (
    <NewCard
      className={st(
        classes.root,
        { mobile },
        classnames(className, styles.gridCard),
      )}
      stacked={true}
    >
      {showGroupLogo ? (
        <NewCard.Container
          className={classnames(styles.mediaContainer, {
            [styles.square]: imageRatio === ImageRatio.square,
            [styles.rectangle]: imageRatio === ImageRatio.rectangle,
          })}
        >
          <GroupCardMedia
            url={coverImage!}
            ratio={imageRatio}
            groupUrl={groupUrl}
            goToGroup={goToGroup}
          />
        </NewCard.Container>
      ) : null}
      <NewCard.Container className={classes.info}>
        <div
          data-hook="group-card-info"
          onClick={goToGroup}
          className={styles.info_grid}
        >
          <GroupCardMeta
            group={group}
            groupUrl={groupUrl}
            className={classes.gridCardMeta}
          />
          <GroupCardCTA group={group} groupUrl={groupUrl} />
        </div>
      </NewCard.Container>
    </NewCard>
  );
};
GroupGridCard.displayName = 'GroupGridCard';
