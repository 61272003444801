import React from 'react';
import { WixSiteMembersContext } from './WixSiteMembersContext';
import { WixSiteMember, WixSiteMemberActions } from './WixSiteMembers';

export const withWixSiteMembers =
  <P extends WixSiteMember & WixSiteMemberActions>(
    WrappedComponent: React.ComponentType<Omit<P, keyof WixSiteMember>>,
  ) =>
  (props: Omit<P, keyof WixSiteMember>): any => {
    return (
      <WixSiteMembersContext.Consumer>
        {(context) => {
          return <WrappedComponent {...context} {...props} />;
        }}
      </WixSiteMembersContext.Consumer>
    );
  };
